import styled from "styled-components";

export const Checkbox = styled.label`
  margin-bottom: ${({ theme }) => theme.customTheme.pxToRem(15)};
  input {
    margin: ${({ theme }) => theme.customTheme.pxToRem(3)};
    margin-right: ${({ theme }) => theme.customTheme.pxToRem(10)};
  }

  ${({ theme }) => theme.customTheme.typography.checkBoxText};
  a {
    ${({ theme }) => theme.customTheme.typography.linkText};
  }
`;

export const Label = styled.span`
  font-family: ThemeFontRegular;
`;
