import { Link } from "react-router-dom";
import styled from "styled-components";

export const BreadcrumbsLink = styled(Link)`
  text-decoration: none;
  ${({ theme }) => theme.customTheme.containers.Breadcrumbs.BreadcrumbsLink};
`;

export const BreadcrumbsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-contnet: flex-start;
  align-items: center;
  border-top: 1px solid rgba(204, 204, 204, 0.4);
  padding: 0px ${({ theme }) => theme.customTheme.pxToRem(10)};
  background-color: ${({ theme }) => theme.customTheme.palette.grey.standard};
  border-shadow: ${({ theme }) => theme.customTheme.palette.borderBoxPageContainer.border};
  height: 50px;

  & > * {
    ${({ theme }) => theme.customTheme.typography.breadcrumbs};
    margin: 0px 0px 0px ${({ theme }) => theme.customTheme.pxToRem(10)};
  }

  ${({ theme }) => theme.customTheme.containers.Breadcrumbs.BreadcrumbsWrapper};
`;

export const BreadcrumbsText = styled.span`
  font-family: ThemeFontRegular;
  ${({ theme }) => theme.customTheme.containers.Breadcrumbs.BreadcrumbsText};
`;
