import styled from "styled-components";

interface ICardGrid {
  numberOfOffers: number;
}
export const CardGrid = styled.div<ICardGrid>`
  display: grid;
  align-self: center;
  max-width: 1000px;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;

  grid-template-columns: auto auto auto auto;
  gap: ${({ theme }) => theme.customTheme.pxToRem(32)};
  margin: ${({ theme }) => theme.customTheme.pxToRem(32)} ${({ theme }) => theme.customTheme.pxToRem(48)};

  ${({ theme }) => theme.customTheme.breakpoints.down("sm")} {
    padding: ${({ theme }) => theme.customTheme.pxToRem(32)} 0px;
  }

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    width: 95%;
  }

  ${({ theme }) => theme.customTheme.containers.HomePage.CardGrid};
`;

export const Tabs = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.customTheme.palette.grey.mid};
  ${({ theme }) => theme.customTheme.containers.HomePage.Tabs};
`;

export const Tab = styled.button<{ active: boolean }>`
  background: ${({ theme }) => theme.customTheme.palette.white.standard};
  border-width: 0px 0px 4px;
  border-bottom-style: solid;
  display: block;
  font-family: ThemeFontLight;
  font-size: ${({ theme }) => theme.customTheme.pxToRem(18)};
  margin: 0px 0.2em;
  transition: all 0.1s ease-in-out 0s;
  z-index: 2001;
  cursor: pointer;
  border-color: ${({ theme, active }) =>
    active ? theme.customTheme.palette.homePageTabs.chosenBorder : "transparent"};
  color: ${({ theme, active }) =>
    active ? theme.customTheme.palette.homePageTabs.chosenText : theme.customTheme.palette.homePageTabs.unchosenText};

  padding: ${({ theme }) => theme.customTheme.pxToRem(8)} ${({ theme }) => theme.customTheme.pxToRem(10)};
  width: 100%;

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    width: 240px;
  }

  :after {
    transition: all 0.1s ease-in-out 0s;
    top: calc(100% + 4px);
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: ${({ theme }) => theme.customTheme.palette.homePageTabs.chosenBorder};
    border-width: ${({ active }) => (active ? "7px" : "0px")};
    margin-left: ${({ active }) => (active ? "-7px" : "0px")};
  }

  &:hover,
  :focus {
    border-color: ${({ theme, active }) => !active && theme.customTheme.palette.homePageTabs.hoverBorder};
    ::after {
      border-top-color: ${({ theme, active }) => !active && theme.customTheme.palette.homePageTabs.hoverBorder};
      border-width: ${({ active }) => !active && "7px"};
      margin-left: ${({ active }) => !active && "-7px"};
    }
  }
  position: relative;
  ${({ theme }) => theme.customTheme.containers.HomePage.Tab};
`;

export const EmptyListText = styled.h3`
  text-align: center;
  ${({ theme }) => theme.customTheme.typography.h3};
`;
