import styled from "styled-components";

export const TooltipBase = styled.div`
  height: ${({ theme }) => theme.customTheme.pxToRem(57)};
  width: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.customTheme.palette.yellow.tooltipArrow};
  border: 1px solid ${({ theme }) => theme.customTheme.palette.yellow.tooltipArrow};
  color: ${({ theme }) => theme.customTheme.palette.white.standard};
  text-align: center;
  border-radius: ${({ theme }) => theme.customTheme.pxToRem(10)};
  margin-bottom: ${({ theme }) => theme.customTheme.pxToRem(8)};

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    width: 88%;
    height: ${({ theme }) => theme.customTheme.pxToRem(70)};
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: ${({ theme }) => theme.customTheme.pxToRem(10)};
    height: ${({ theme }) => theme.customTheme.pxToRem(10)};
    background-color: ${({ theme }) => theme.customTheme.palette.yellow.tooltipArrow};
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: ${({ theme }) => theme.customTheme.pxToRem(27)};
    pointer-events: none;
  }
`;

export const ToolTipText = styled.span`
  font-family: ThemeFontRegular;
  display: inline-flex;
  width: 89%;
  height: 100%;
  background-color: ${({ theme }) => theme.customTheme.palette.grey.info};
  margin-left: 11%;
  border-radius: ${({ theme }) => theme.customTheme.pxToRem(0)} ${({ theme }) => theme.customTheme.pxToRem(10)}
    ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(0)};
  text-align: left;
  align-items: center;
  padding: ${({ theme }) => theme.customTheme.pxToRem(16)};
  line-height: 1.4;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    margin-left: 17%;
    width: 83%;
  }
`;
