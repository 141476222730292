import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import {
  StyledCard,
  CardContent,
  Subheading,
  Heading,
  CardHeader,
  CardHeaderLeft,
  SeeComparisonLine,
  ComparisonLine,
  ComparisonCell,
} from "components/StyledCard";
import { LinkAsSecondaryButton } from "components/Button";

import Offer from "types/Offer";
import TileImage from "components/TileImage";
import PartnerLogo from "components/PartnerLogo";

import Arrow from "components/Arrow";
import { getPageByStatusOffer } from "utils/getPageByStatusOffer";
import getOpco from "utils/getOpco";
import { configState } from "state/config-slice";

interface IAvailableCardProps {
  offer: Offer;
  numberOfOffers: number;
  offerPosition: number;
}

const OfferCard = ({ offer, numberOfOffers, offerPosition }: IAvailableCardProps) => {
  const { heading, pages, partner, statusOffer, type, comparison } = offer || {};
  const { cdnUrl } = useSelector(configState);

  const { t } = useTranslation();

  const cardDetails = pages[getPageByStatusOffer(statusOffer)];

  return (
    <StyledCard
      to={`${type === "comparison" ? `/comparison/${partner}` : `/offer/${partner}-${type}`}`}
      id={`offer_${partner}`}
      numberofoffers={numberOfOffers}
      offerposition={offerPosition}
    >
      <TileImage partner={partner} url={`${cdnUrl}${partner}_${getOpco()}_480.jpg`} />
      <CardContent>
        <CardHeader>
          <CardHeaderLeft>
            <PartnerLogo partner={partner} url={`${cdnUrl}${partner}_logo.png`} />
            <Heading>{heading}</Heading>
          </CardHeaderLeft>
          <Arrow direction={"right"} size={16} />
        </CardHeader>

        {/* cardDetails?.tileStrapline && <PriceText>{composeEndDate(cardDetails.tileStrapline, endDate)}</PriceText>*/}
        {cardDetails?.details.subHeading && type !== "comparison" && (
          <Subheading>{cardDetails?.details.subHeading}</Subheading>
        )}
        {type === "comparison" && (
          <>
            <SeeComparisonLine>
              <Subheading>{t("See comparison")}</Subheading>
              <Arrow direction={"right"} size={16} />
            </SeeComparisonLine>
            <ComparisonLine>
              {comparison?.offerNames.map((el: { name: string; presentationName: string }) => (
                <ComparisonCell key={`offerNameGetButton${el.presentationName}ButtonCell`}>
                  <span>{el.presentationName}</span>
                  <LinkAsSecondaryButton
                    to={`/offer/${el.name}`}
                    key={`offerNameGetButton${el.presentationName}Button`}
                    id={`offerNameGetButton${el.presentationName}Button`}
                  >
                    {t("Get")}
                  </LinkAsSecondaryButton>
                </ComparisonCell>
              ))}
            </ComparisonLine>
          </>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default OfferCard;
