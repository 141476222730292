// NOTE: These are the default values from Material-UI and _not_ based on the designs for this project.
const values = {
  xs: 0,
  sm: 300,
  md: 680,
  mdl: 1024,
  lg: 1200,
  xl: 1536,
  xxl: 2000,
};

const breakpoints = {
  values: { ...values },
  unit: "px",
  up: (measure: keyof typeof values): string => `@media (min-width:  ${values[measure]}px)`,
  down: (measure: keyof typeof values): string => `@media (max-width: ${values[measure]}px)`,
};

export default breakpoints;
