import { FunctionComponent, useState, Fragment, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useTheme } from "styled-components";

import { Trans } from "react-i18next";

import { t } from "i18next";

import { IconSecurity, IconContactUs, IconChat } from "@vfuk/uk-legacy-icons";

import {
  OfferDetailsSection,
  OfferDetailsHeader,
  OfferDetailsMainText,
  OfferDetailsSectionMainSection,
  OfferDetailsAditionalText,
  OfferDetailsSubHeading,
  BottomSection,
  BottomSectionTitle,
  BottomSectionSubTitle,
  BottomSectionWrapper,
  HelpLink,
  ActivationWarning,
  ActivationWarningText,
  DownloadAnchor,
  DownloadButtonsContainer,
  DownloadImage,
  HelpLinkLabel,
  OfferBanner,
} from "./styles";

import { downloadValues } from "./downloadInfo";

import { configState, sendULFEvent, setModal } from "state/config-slice";
import { ULFEvents } from "types/ULF";

import { AppDispatch } from "state/reducers-combiner";

import CustomCheckbox from "components/CustomCheckbox";

import { RenderButtonComponent } from "components/Button";

import PartnerLogo from "components/PartnerLogo";

import { PageContainer } from "containers/PageContainer";

import {
  selectOfferByName,
  purchaseOffer,
  revokeCancellation,
  offersState,
  setPurchasing,
  setCanceling,
  getAccountrecoveryURl,
} from "state/offers-slice";
import Offer from "types/Offer";
import { termsAndConditionsVariableChange } from "utils/termsAndConditionsVariableChange";
import CustomTolltip from "components/CustomTooltip";
import { getPageByStatusOffer } from "utils/getPageByStatusOffer";
import getOpco from "utils/getOpco";
import { getDeviceType } from "utils/deviceDetect";
import PurchaseLoader from "containers/PurchaseLoader";
import FeedbackModal from "containers/Modal/FeedbackModal";
import CancelLoader from "containers/CancelLoader";

const OfferDetails: FunctionComponent = () => {
  const { name } = useParams<{ name?: string }>();
  const theme = useTheme();
  const [checkboxAccepted, setCheckboxAccepted] = useState<boolean>(false);
  const [showTermsConMessage, setShowTermsConMessage] = useState<boolean>(false);
  const { purchasing, canceling } = useSelector(offersState);
  const { cdnUrl } = useSelector(configState);
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const selectedOffer: Offer = useSelector(selectOfferByName(name));

  const actionType = selectedOffer && getPageByStatusOffer(selectedOffer.statusOffer);
  const isCancelled = selectedOffer?.statusOffer?.toLowerCase() === "cancelled";
  const helpSection = selectedOffer?.pages[actionType]?.details?.help;
  const partnerInfoForDownload = downloadValues[getOpco()][selectedOffer.partner.toLowerCase()];

  const offerDetails = useMemo(() => {
    if (selectedOffer) {
      const currentPage = selectedOffer.pages[actionType];

      return isCancelled ? currentPage.success : currentPage.details;
    }
  }, [selectedOffer, actionType, isCancelled]);

  const buttonBeforeBody = useMemo(() => {
    return actionType === "purchase";
  }, [actionType]);

  const DownloadIOS = useMemo(() => {
    return getDeviceType() !== "Android";
  }, []);

  const DownloadAndroid = useMemo(() => {
    return getDeviceType() !== "iOS";
  }, []);

  useEffect(() => {
    if (checkboxAccepted) {
      setShowTermsConMessage(false);
    }
  }, [checkboxAccepted]);

  const processButtonClick = (actionType: string, action: string) => {
    if (actionType === "link") {
      dispatch(
        sendULFEvent({
          "event-name": ULFEvents.REDIRECT_TO_EXTERNAL_LINK,
          "offer-name": selectedOffer.name,
          partner: selectedOffer.partner,
          link: action,
        }),
      );

      window.location.replace(action);
    }

    if (actionType === "in-app" && action === "purchase") {
      if (checkboxAccepted) {
        dispatch(
          sendULFEvent({
            "event-name": ULFEvents.SUBSCRIPTION_PURCHASE_CLICKED,
            "offer-name": selectedOffer.name,
            partner: selectedOffer.partner,
          }),
        );

        dispatch(
          purchaseOffer({
            purchaseString: selectedOffer.purchaseString,
            partner: selectedOffer.partner,
            offerName: selectedOffer.name,
            type: selectedOffer.type,
            longPackageId: selectedOffer.longPackageId,
            packageId: selectedOffer.packageId,
            subscriptionId: selectedOffer.subscriptionId,
          }),
        )
          .unwrap()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((originalPromiseResult: any) => {
            if (originalPromiseResult.status === 500) {
              dispatch(setPurchasing(0));
              navigate("/error");
            }
          });
      } else {
        setShowTermsConMessage(true);
      }
    }

    if (actionType === "in-app" && action === "cancellation") {
      dispatch(
        setModal({
          showModal: true,
          cross: false,
          type: "logout",
          children: <FeedbackModal selectedOffer={selectedOffer} />,
          customButtons: <></>,
        }),
      );
    }

    if (actionType === "in-app" && action === "reactivate") {
      dispatch(
        sendULFEvent({
          "event-name": ULFEvents.REACTIVATE_CLICKED,
          "offer-name": selectedOffer.name,
          partner: selectedOffer.partner,
        }),
      );

      dispatch(revokeCancellation({ packageId: selectedOffer.packageId }));
    }
  };

  const iconRenderer = (iconType: string) => {
    switch (iconType) {
      case "accountRecovery":
        return <IconSecurity size={2} inverse={false} isResponsive />;
      case "customerCare":
        return <IconContactUs size={2} inverse={false} isResponsive />;
      case "faq":
        return <IconChat size={2} inverse={false} isResponsive />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <PageContainer customPageStyling={theme.customTheme.containers.OfferDetails.PageContainer}>
        {purchasing > 0 && <PurchaseLoader percent={purchasing} setPercent={setPurchasing} />}
        {canceling > 0 && <CancelLoader percent={canceling} setPercent={setCanceling} offer={selectedOffer} />}
        <theme.customTheme.components.Breadcrumbs text={t("Offer Detail")} />
        <OfferBanner background={`${cdnUrl}${selectedOffer.partner}_${getOpco()}`} id="offer-details-page-banner" />
        <OfferDetailsSection>
          <OfferDetailsSectionMainSection>
            <OfferDetailsHeader>
              <PartnerLogo
                data-testid="od-partner-logo"
                partner={selectedOffer.partner}
                url={`${cdnUrl}${selectedOffer.partner}_logo.png`}
              />
            </OfferDetailsHeader>
            {!isCancelled && (
              <OfferDetailsSubHeading data-testid="od-subheading">{offerDetails.subHeading}</OfferDetailsSubHeading>
            )}
            {/* offerDetails.priceHeading && (
              <OfferDetailsSubHeading data-testid="od-priceheading">{offerDetails.priceHeading}</OfferDetailsSubHeading>
            )*/}
            {/* offerDetails.expiryRenewal && (
              <OfferDetailsSubHeading data-testid="od-expiryRenewal">
                {composeEndDate(offerDetails.expiryRenewal, selectedOffer.endDate)}
              </OfferDetailsSubHeading>
            ) */}
            {checkboxAccepted && offerDetails.checkbox?.confirmationMessage && (
              <ActivationWarning data-testid="warn-activation">
                <ActivationWarningText>{offerDetails.checkbox.confirmationMessage}</ActivationWarningText>
              </ActivationWarning>
            )}
            {showTermsConMessage && (
              <CustomTolltip id="terms-con-error">{offerDetails.checkbox.errorMessage}</CustomTolltip>
            )}
            {offerDetails.checkbox && offerDetails.checkbox.mandatory && (
              <CustomCheckbox
                id="terms_checkbox"
                checked={checkboxAccepted}
                label={termsAndConditionsVariableChange(offerDetails.checkbox)}
                onChange={setCheckboxAccepted}
              ></CustomCheckbox>
            )}

            {offerDetails.okButton && buttonBeforeBody && (
              <RenderButtonComponent
                type="primary"
                title={offerDetails.okButton.label}
                onClick={() => processButtonClick("in-app", actionType)}
                key="key"
                id={"acceptTermsPagePurchaseOfferButton"}
              />
            )}
            <OfferDetailsMainText dangerouslySetInnerHTML={{ __html: offerDetails.primaryText }} />

            {actionType === "cancellation" && (
              <DownloadButtonsContainer>
                {DownloadAndroid && (
                  <DownloadAnchor data-testid="od-partner-google-url" href={partnerInfoForDownload?.url_google}>
                    <DownloadImage
                      data-testid="od-partner-google-donwload"
                      src={partnerInfoForDownload?.img_google}
                      alt="download_for_google"
                    />
                  </DownloadAnchor>
                )}
                {DownloadIOS && (
                  <DownloadAnchor data-testid="od-partner-apple-url" href={partnerInfoForDownload?.url_apple}>
                    <DownloadImage
                      data-testid="od-partner-apple-donwload"
                      src={partnerInfoForDownload?.img_apple}
                      alt="download_for_apple"
                    />
                  </DownloadAnchor>
                )}
                {DownloadAndroid && DownloadIOS && (
                  <RenderButtonComponent
                    type="secondary"
                    title={partnerInfoForDownload?.desktopInfo.label}
                    onClick={() => (window.location.href = partnerInfoForDownload?.desktopInfo.url)}
                    key="buttonGetAppDesktop"
                    id={"buttonGetAppDesktop"}
                  />
                )}
              </DownloadButtonsContainer>
            )}
          </OfferDetailsSectionMainSection>
        </OfferDetailsSection>
        {offerDetails.additionalContent && (
          <OfferDetailsSection data-testid="od-details-section">
            {offerDetails.additionalContent.panels.map((panel: { text: string }) => (
              <OfferDetailsAditionalText
                data-testid="od-aditional-text"
                dangerouslySetInnerHTML={{ __html: panel.text }}
                key={`panel${panel.text}`}
              />
            ))}
          </OfferDetailsSection>
        )}
        {helpSection && (
          <BottomSectionWrapper>
            {helpSection && (
              <BottomSection data-testid="od-help-section">
                <BottomSectionTitle>
                  <Trans i18nKey="Help" />
                </BottomSectionTitle>
                {Object.values(helpSection).map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (value: any, i: number): any =>
                    value.label && (
                      <HelpLink
                        data-testid={`od-help-link-${Object.keys(helpSection)[i]}`}
                        onClick={() => {
                          if (Object.keys(helpSection)[i] === "accountRecovery")
                            dispatch(getAccountrecoveryURl({ partner: selectedOffer.partner }));
                        }}
                        key={`helpItem${value.label}`}
                        href={value.url}
                      >
                        {iconRenderer(Object.keys(helpSection)[i])}
                        <HelpLinkLabel>{value.label}</HelpLinkLabel>
                      </HelpLink>
                    ),
                )}
              </BottomSection>
            )}
            {offerDetails.okButton && !buttonBeforeBody && (
              <BottomSection>
                <BottomSectionTitle data-testid="od-manage-section">
                  <Trans i18nKey="Manage your subscription" />
                </BottomSectionTitle>
                {offerDetails.buttons?.manageSection.map(
                  (
                    button: { label: string; action: string; description: string; actionType: string; type: string },
                    index: number,
                  ) => (
                    <Fragment key={`buttonTitle${button.action}${index}`}>
                      <BottomSectionSubTitle>{button.description}</BottomSectionSubTitle>
                      <RenderButtonComponent
                        type={button.type}
                        title={button.label}
                        onClick={() => processButtonClick(button.actionType, button.action)}
                        key={`button${button.action}${index}`}
                        id={`button${button.action}`}
                      />
                    </Fragment>
                  ),
                )}
                <RenderButtonComponent
                  type="primary"
                  title={offerDetails.okButton.label}
                  onClick={() => processButtonClick("in-app", actionType)}
                  key="key"
                  id={"cancel-purchase-btn"}
                />
              </BottomSection>
            )}
          </BottomSectionWrapper>
        )}
      </PageContainer>
    </>
  );
};

export default OfferDetails;
