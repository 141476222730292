import { Link } from "react-router-dom";
import styled from "styled-components";

interface ILinkProps {
  numberofoffers: number;
  offerposition: number;
}

export const StyledCard = styled(Link)<ILinkProps>`
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;

  &:visited {
    text-decoration: none;
    color: black;
  }
  border-radius: 5px;
  height: auto;
  -webkit-box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.1);
  grid-column: span 6;
  flex-direction: column;

  & > img {
    min-height: 216px;
    max-height: 320px;
    height: 320px;
  }

  & > div {
    height: auto;
  }
  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    & > img {
      height: 330px;
    }
  }

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    ${({ offerposition, numberofoffers }) =>
      Math.floor(numberofoffers / 2) * 2 > offerposition
        ? `
            grid-column: span 2;
            & > img {
                min-height:320px;
              }
            `
        : `
            grid-column: span 6;
            flex-direction:row;
            & > img {
                width:350px;
                max-height:330pxpx;
                min-height: 250px;
              }
            `}
  }
  ${({ theme }) => theme.customTheme.breakpoints.up("mdl")} {
    ${({ offerposition, numberofoffers }) =>
      Math.round(Math.floor(numberofoffers / 2) * 2) > offerposition
        ? `
            grid-column: span 2;
            & > img {
                min-height:320px;
                max-width:100%;
              }
            `
        : `
            grid-column: span 6;
            flex-direction:row;
            & > img {
                width:480px;
                max-height:330px;
                min-height: 250px;
              }
            `}
  }
`;

export const CardContent = styled.div`
  padding: ${({ theme }) => theme.customTheme.pxToRem(15)};
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.customTheme.containers.Card.CardContent};
`;

export const PriceText = styled.b`
  ${({ theme }) => theme.customTheme.typography.b1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  ${({ theme }) => theme.customTheme.containers.Card.PriceText};
`;

export const Subheading = styled.span`
  font-family: ThemeFontLight;
  ${({ theme }) => theme.customTheme.typography.span2};
  ${({ theme }) => theme.customTheme.containers.Card.Subheading};
`;

export const Heading = styled.span`
  font-family: ThemeFontLight;
  ${({ theme }) => theme.customTheme.typography.span1};
  ${({ theme }) => theme.customTheme.containers.Card.Heading};
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${({ theme }) => theme.customTheme.containers.Card.CardHeader};
`;

export const CardHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.customTheme.pxToRem(20)};
  ${({ theme }) => theme.customTheme.containers.Card.CardHeaderLeft};
`;

export const SeeComparisonLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ComparisonCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ComparisonLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.customTheme.pxToRem(20)};
`;
