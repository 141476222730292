import styled from "styled-components";

import { PrimaryButton } from "components/Button";
import { Section } from "containers/Section/styles";

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  gap: 16px;
  margin: 0px auto;
  margin-top: 88px;
`;

export const BannerSection = styled(Section)`
  background-color: ${({ theme }) => theme.customTheme.palette.black.dark};
  ${({ theme }) => theme.customTheme.containers.HomePage.BannerSection};
`;

export const StyledBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px 0px;
  svg {
    display: none;
  }

  > :last-child {
    > button {
      border-top: 1px solid rgb(214, 214, 214);
      font-size: ${({ theme }) => theme.customTheme.pxToRem(20)};
      padding: 16px 0px;
      font-weight: normal;
      padding: 16px 32px;
    }
    > div {
      padding: 16px 32px;
      padding-top: 0px;
      & dd,
      dt {
        font-size: ${({ theme }) => theme.customTheme.pxToRem(16)};
      }

      & dd {
        margin-left: 6px;
      }

      & dd:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
`;

export const StyledContentArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
`;

export const CookieHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const CookieDescription = styled.p`
  font-family: ThemeFontRegular;
  font-size: ${({ theme }) => theme.customTheme.pxToRem(16)};
  margin-bottom: 0;
`;

export const CookieTitle = styled.h3`
  font-family: ThemeFontRegular;
  margin-bottom: 0;
  line-height: ${({ theme }) => theme.customTheme.pxToRem(24)};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(24)};
`;

export const CookieAlwaysOn = styled.p`
  font-family: ThemeFontRegular;
  font-weight: 700;
  margin: auto 16px;
  font-size: ${({ theme }) => theme.customTheme.pxToRem(16)};
`;

export const Title = styled.h1`
  font-family: ThemeFontRegular;
`;

export const CollapsibleText = styled.span`
  font-family: ThemeFontRegular;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 32px;
  gap: 32px;
`;

export const CookiesBtn = styled(PrimaryButton)`
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(24)};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(18)};
  width: 100%;
  background-color: ${({ theme }) => theme.customTheme.palette.black.black25};
`;
