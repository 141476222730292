import { FunctionComponent } from "react";

import { Checkbox, Label } from "./styles";

interface IProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  label: string;
  id?: string;
}

const CustomCheckbox: FunctionComponent<IProps> = ({ checked, onChange, label, id }) => {
  return (
    <Checkbox>
      <input type="checkbox" data-testid={id} checked={checked} onChange={() => onChange(!checked)} />
      <Label dangerouslySetInnerHTML={{ __html: label }} />
    </Checkbox>
  );
};

export default CustomCheckbox;
