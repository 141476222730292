interface ResponseType {
  redirectUrl: string;
  location: string;
  packageId: string;
  toActivate: boolean;
}

const redirectPage = (response: ResponseType) => {
  if (response.redirectUrl) {
    window.location.href = response.redirectUrl;
    return;
  }
};

export default redirectPage;
