import { FunctionComponent, useCallback, useState } from "react";
// import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { IconClose } from "@vfuk/uk-legacy-icons";

import { IconHelpCircle } from "@vfuk/source-hifi-light-icons";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { StyledBoxArea, LogoutButton, HeaderLogOut } from "../LogoutModal/styles";

import { FeedBackModalContainer, StyledPageFeedbackTitle } from "./styles";

import { sendULFEvent, setModal } from "state/config-slice";
import RadioButtonGroup from "components/Inputs/RadioButtonGroup";
import { cancelSubscription, setCanceling } from "state/offers-slice";
import { ULFEvents } from "types/ULF";
import Offer from "types/Offer";

interface IFeedBackProps {
  selectedOffer: Offer;
}

const FeedbackModal: FunctionComponent<IFeedBackProps> = ({ selectedOffer }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState<string>();

  const cancelSub = useCallback(() => {
    dispatch(
      sendULFEvent({
        "event-name": ULFEvents.CANCEL_CLICKED,
        "offer-name": selectedOffer?.json?.name,
        partner: selectedOffer.partner,
        reason: selectedValue,
      }),
    );

    dispatch(
      cancelSubscription({
        subscription_id: selectedOffer.subscriptionId,
        partner: selectedOffer.partner,
        offerName: selectedOffer.name,
      }),
    )
      .unwrap()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((originalPromiseResult: any) => {
        if (originalPromiseResult.status === 500) {
          dispatch(setCanceling(0));
          navigate("/error");
        }
      });

    dispatch(
      setModal({
        showModal: false,
        cross: false,
        type: "logout",
        children: <FeedbackModal selectedOffer={selectedOffer} />,
        customButtons: <></>,
      }),
    );
  }, [dispatch, navigate, selectedOffer, selectedValue]);

  const feedback = [
    {
      label: t("I dont use it enough"),
      name: "feedback",
    },
    {
      label: t("The service is not working as expected"),
      name: "feedback",
    },
    {
      label: t("The service is too expensive"),
      name: "feedback",
    },
    {
      label: t("I just wanted to test it for a limited time"),
      name: "feedback",
    },
    {
      label: t("None of the above"),
      name: "feedback",
    },
  ];

  function radioGroupHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedValue(event.target.value);
  }

  return (
    <FeedBackModalContainer>
      <HeaderLogOut>
        <span
          onClick={() => {
            dispatch(setModal({ showModal: false }));
          }}
          onKeyDown={() => {
            dispatch(setModal({ showModal: false }));
          }}
          role="button"
          data-testid="feedback-close-cancel-btn"
          tabIndex={0}
        >
          <IconClose inverse={false} />
        </span>
      </HeaderLogOut>
      <IconHelpCircle size={5} isResponsive />
      <StyledPageFeedbackTitle>{t("Cancellation confirmation")}</StyledPageFeedbackTitle>
      <RadioButtonGroup
        label={t("Please tell us the reason why you canceled")}
        options={feedback}
        onChange={radioGroupHandler}
      />
      <StyledBoxArea>
        <LogoutButton
          id="cancel-purchase-with-feedback-btn"
          onClick={cancelSub}
          title={t("Cancel and send feedback")}
        />
      </StyledBoxArea>
    </FeedBackModalContainer>
  );
};

export default FeedbackModal;
